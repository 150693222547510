.title {
  font-size: 20px;
}

.subtitle {
  font-size: 16px;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.font-Montserrat {
  font-family: 'Montserrat', sans-serif !important;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.common-m-tb {
  margin: 80px 0px;
}

.common-header-width {
  width: 90%;
  margin: 0 auto;
}

.main-body-section {
  width: 85%;
  margin: 0 auto;
}

.common-title {
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.common-subtitle {
  color: #4B4B4B;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.common-m-subtitle {
  margin: 15px 0px;
}

.common-flex {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

.content {
  flex: 1;
  padding-right: 30px;
}

.heading {
  font-size: 40px;
  color: #e44d26;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.right-explore-common {
  width: 50%;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-btn {
  color: #FFF;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 8px;
  background: linear-gradient(90deg, #01A00E 0%, #026D0B 100%);
  width: 179px;
  height: 56px;
  border-radius: 16px;
  border: unset;
  cursor: pointer;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
}

.discover-img-responsive {
  width: 100%;
}

.discover-img-responsive img {
  width: 100%;
  border-radius: 20px;
}

.img-discover {

  border-radius: 20px;
}

.flex-section {
  display: flex;
  justify-content: space-between;
}

.discover-m {
  margin-right: 15px;
}

.number-label {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.number-subtitle {
  color: #0F172A;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  margin: 10px 10px 10px 0px;
}

.common-border {
  border-right: 3px solid grey;
  padding-right: 15px;
}

.m-t-35 {
  margin-top: 35px;
}

.common-width-50 {
  width: 50%;
}

.pdding-right-40 {
  padding-right: 40px;
}

.pdding-left-40 {
  padding-left: 40px;
}

.m-t-0 {
  margin: 0px;
}

.common-flex-delivery-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.border-bottom-commit {
  border-bottom: 2px solid grey;
  padding-bottom: 30px;
}

.approach-main-section-bg {
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 0px 10px 0px #FFF inset;
  border-radius: 30px;
  background: linear-gradient(0deg, rgba(3, 80, 8, 0.65) 0%, rgba(3, 80, 8, 0.65) 100%), url('../images/approach.jpeg')lightgray -4px -305.627px / 100.543% 214.82% no-repeat;
  padding: 130px 80px;
  height: 250px;
}

.approach-title {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px 0px 24px 0px;
}

.approach-subtitle {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0px;
}

.m-btn-contact {
  margin-top: 30px;
}

.contact-sub-m-t {
  margin: 20px 0px;
}

.contact-m-t {
  margin: 20px 0px;
}

.m-t-0 {
  margin: 0px;
}

.input-field-style input,
label {
  color: #0F172A !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-family: 'Roboto', sans-serif !important;
}

.explore-flex {
  display: flex;
}

.explore-m-left {
  margin-top: 95px;
}

.explore-m-right {
  margin-top: 50px;
}

.readmore {
  color: #01A00E;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid #01A00E;
  background-color: #ffffff;
  cursor: pointer;
  padding: 10px 15px;
  margin-top: 20px;

}

.explore-m-left {
  margin-top: 144px;
}

.mobile-app-img-m {
  margin: 20px 0px;
}

.common-img-width-explore {
  width: 40px;
  height: 40px
}

.explore-item {
  display: flex;
  gap: 23px;
}

.explore-common-title {
  color: var(--Blue-Gray-900, #0F172A);
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin: 0px 0px 10px 0px;
}

.ex-subtitle {
  color: #4B4B4B;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.ex-sublabel {
  color: #4B4B4B;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 166.667% */
}

.expl-m-t {
  margin-top: 20px;
}

.bgclr-checksection {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 20px;
}

.common-subtitle-who-we {
  color: #606060;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
}

.common-app-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.width-app-store {
  width: 100%;
}

.mobile-app-img-m {
  width: 100%;
}

.mobile-app-img-m img {
  width: 80%;
}

.m-tb-30 {
  margin: 32px 0px;
}

.app-sublabel {
  color: #4B4B4B;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  /* 191.667% */
}

.mission-item {
  display: flex;
  gap: 23px;
  border-radius: 16px;
  background: #F9FFF9;
  padding: 10px;
  margin: 0px;
}

.mission-subtitle {
  color: #4B4B4B;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.mission-common-title {
  color: var(--Blue-Gray-900, #0F172A);
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin: 0px 0px 10px 0px;
}

.mission-m-t {
  margin-top: 20px;
}

.mission-m-left {
  width: 60%;
}

.right-container-common {
  width: 40%;
}

.phone-issue-padding {
  padding-bottom: 14px !important;
}

.footer-bototm-link a.privacy-m {
  margin-left: 150px;
}

.who-are-width {
  width: 70%;
}

.who-are-m-t {
  margin-top: 0px;
}

.slider-sublabel {
  color: var(--White, #FFF);
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 38.4px */
  margin: 0px;
}

.slider-name {
  display: flex;
}

.textmonial-title {
  color: #0F172A;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  /* 61.6px */
}

.textmonial-sublabel {
  color: #0F172A;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 50px;
  /* 38.4px */
}

.textmonial-subtitle {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  line-height: 30px;
}

.textmonial-name {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  text-align: left;
}

.textmonial-address {
  color: #9CC9A1;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 8px 0px 0px 0px;
  text-align: left;
}

.text-center {
  text-align: center;
}

/* footer */
.footer-logo-title {
  color: #179800;
  font-family: 'Inter', sans-serif !important;
  font-size: 27.692px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.231px;
  /* 120% */
  letter-spacing: 0.692px;
}

.footer-logo-sublabel {
  color: #606060;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
}

.footer-link-title {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.231px;
  /* 166.154% */
  letter-spacing: 0.692px;
}

.footer-link {
  color: #979797 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 33.231px;
  text-decoration: none;
  letter-spacing: 0.692px;
  cursor: pointer;
}

.socail-label {
  color: #979797;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.231px;
  margin: 0px;
  letter-spacing: 0.692px;
}

.footer-bototm-link {
  color: #979797;
  font-family: 'Montserrat', sans-serif;
  color: #979797;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.231px; /* 207.692% */
  letter-spacing: 0.692px;
}

.footer-bototm-link a {
  color: #979797;
  font-family: 'Montserrat', sans-serif;
  color: #979797;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.231px; /* 207.692% */
  letter-spacing: 0.692px;
}

.footer-link-style {
  font-family: 'Montserrat', sans-serif;
  color: #0F172A !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.footer-link-style:hover {
  color: #01A00E !important;
}

.footer-logo {
  margin-right: 20px;
  vertical-align: sub;
}

.bg-client {
  background: linear-gradient(90deg, #F8FDF9 0%, #F5FBF4 49.5%, #EBF5EA 83.1%, #EEF8ED 100%) !important;
  padding: 59px 0px;
}

.video-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 10px;
  /* Optional rounded corners */
}

.responsive-video {
  width: 100%;
  max-height: 450px;
  object-fit: contain;
  border-radius: 10px;
}

.map {
  width: 100%;
}

.map img {
  width: 100%;
}
.banner-m-tb{
  margin: 34px 0px;
}
.logo-mobile{
  width: 400px; 
  max-Width: 400px;
  height: 96px;
}
@media(max-width:1680px) {
  .common-title {
    font-size: 40px;
  }

  .number-label {
    font-size: 40px;
  }

  .approach-title {
    font-size: 40px;
  }

  .common-subtitle {
    font-size: 18px;
  }

  .approach-subtitle {
    font-size: 18px;
  }
}

@media(max-width:1400px) {
  .common-title {
    font-size: 30px;
  }

  .number-label {
    font-size: 30px;
  }

  .approach-title {
    font-size: 30px;
  }

  .common-subtitle {
    font-size: 18px;
  }

  .approach-subtitle {
    font-size: 18px;
  }
}

@media(max-width:1100px) {
  .common-title {
    font-size: 25px;
  }

  .number-label {
    font-size: 25px;
  }

  .approach-title {
    font-size: 25px;
  }

  .common-subtitle {
    font-size: 18px;
  }

  .approach-subtitle {
    font-size: 18px;
  }

  .textmonial-title {
    font-size: 30px;
  }

  .textmonial-sublabel {
    font-size: 20px;
  }
}

/* Responsive Design */
@media (max-width: 767px) {
  .main-body-section {
    width: 90%;
  }

  .common-m-tb {
    margin: 30px 0px;
  }

  .common-flex {
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }

  .pdding-left-40 {
    padding-left: 0px;
  }

  .download-btn {
    width: 100%;
  }

  .pdding-right-40 {
    padding-right: 0px;
  }

  .common-width-50 {
    width: 100%;
  }
  .content {
    padding-right: 0;
  }

  .image-container {
    margin-top: 20px;
  }

  .common-flex-delivery-section {
    align-items: center;
    flex-direction: column;
  }

  .m-tmobile-35 {
    margin-top: 35px;
  }

  .padding-mobile {
    padding: 10px;
  }

  .m-subscrime-mobile {
    margin-top: 20px;
  }

  .mobil-m-delevery {
    margin-top: 30px;
    order: -1;
  }

  .margin-top-appstore {
    margin-top: 90px;
  }

  .explore-flex {
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }

  .right-explore-common {
    width: 100%;
  }

  .readmore {
    width: 100%;
  }

  .common-app-flex {
    flex-direction: column;
    width: 100%;
  }

  .width-app-store {
    width: 100%;
    text-align: center;
    margin-top: 55px;
  }

  .approach-main-section-bg {
    text-align: center;
    padding: 130px 30px;
    height: 453px;
  }

  .explore-m-left {
    margin-top: 85px;
  }

  .flex-colum-b-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ex-sublabel {
    font-size: 16px;
  }

  .textmonial-subtitle {
    font-size: 16px;
  }

  .textmonial-title {
    font-size: 25px;
  }

  .app-sublabel {
    font-size: 16px;
  }

  .footer-logo-sublabel {
    font-size: 18px;
  }

  .mission-m-left {
    width: 100%;
  }

  .who-are-width {
    width: 100%;
    margin-top: 30px;
  }

  .right-container-common {
    width: 100%;
  }

  .footer-bototm-link a.privacy-m {
    margin-left: 0px;
  }

  .explore-common-title {
    font-size: 20px;
  }

  .approach-main-section-bg {
    background-size: contain;
    background-position: center;
    padding: 80px 40px;
    height: 453px;
   
  }

  .responsive-video {
    max-height: 250px;
  }

  .footer-logo-title {
    text-align: left;
  }

  .footer-logo-sublabel {
    text-align: left;
  }
  .banner-m-tb{
    margin: 28px 0px;
  }
  .logo-mobile{
    width: 200px;
    height: 80px;
    max-Width: 200px;
    
  }
}

@media(max-width:719px) {
  .common-title {
    font-size: 25px;
  }

  .approach-title {
    font-size: 25px;
  }

  .number-label {
    font-size: 25px;
  }
}
