/* .header-bg-color{
  background: #ebf5ea !important
} */
.header-full-bg-color{
  background: linear-gradient(180deg,  
  #F8FDF9 0%,  
  #F5FBF4 50%,  
  #EBF5EA 83%,  
  #E1DFD1 100%);
}

.header-link-label{
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.header-link-label span{
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize; 
}
.header-m-link{
    margin: 0px !important;
}
.login-btn-style{
    background: linear-gradient(to right, #01A00E, #026D0B);
    color: #ffffff;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    width: 179px;
    height: 56px;
    border: 1px solid #026D0B;
    cursor: pointer;
    border-radius: 8px;
}

.active-link {
    border-bottom: 3px solid #01A00E; 
  }
  .active-link p{
    color: #01A00E !important; 
  }
 
  .footer-bottom-link{
    font-family: 'Inter', sans-serif !important;
    color: #333333 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
  }
  .footer-bottom-link:hover{
    color: #01A00E !important;
  }
  .logo{
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background:  linear-gradient(90deg, #01A00E 0%, #026D0B 100%);
  }
  @media(max-width:1680px){
    .header-link-label {
        font-size: 16px !important;
    }
    .footer-link-style {
        font-size: 18px !important;
    }
  }
  @media(max-width:1400px){
    .header-link-label {
        font-size: 16px !important;
    }
  }
      @media(max-width:1100px){
    .header-link-label {
        font-size: 14px !important;
    }
   
  }
  @media (max-width: 768px) {
    .login-btn-style {
      display: none !important; /* Hide in mobile */
    }
  }
  