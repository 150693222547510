.list-data-privacy{
  padding: 0px;   
}

.list-data-privacy li{
    margin: 0px;
    color: #000;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
.main-body-privacy{
    width: 70%;
    margin: 0 auto;
}
.privacy-margin-tb{
    margin: 150px 0px 100px;
}

@media(max-width:768px){
    .main-body-privacy {
        width: 81%;
        margin: 0 auto;
    }
    .privacy-margin-tb {
        margin: 76px 0px 100px;
    }
}