
.home-banner-bg{
    background: linear-gradient(180deg, #F2F9F1 0%, #F5FAF4 33%, #F6FBF5 66%, #f3f9f2 100%)
}
.hero-m-t{
    margin: 0px;
}
.hero-p-t{
   padding-top: 100px;
   padding-bottom: 15px;
}
.hero-textlign{
    text-align: center;
}
.green-colr{
    color: #179800 !important;
}
.hero-img-center img{
    width: 100%;
}
.main-section-hero {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
  
  .hero-img-center img {
    max-width: 100%;
    height: 600px;
    display: block; /* Prevents extra space below the image */
    object-fit: contain; /* Ensures the whole image is visible */
}
  
 .main-section-hero .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
 .main-section-hero .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
  }
  
  .next {
    right: 10px;
  }
  
  .prev {
    left: 10px;
  }
  
@media(max-width:719px){
    .hero-p-t {
        padding-top: 40px;
        padding-bottom: 15px;
    }
    .hero-img-center img {
        height: auto;
      }
    
      .slick-arrow {
        width: 35px;
        height: 35px;
      }
}